import { current } from "newgraph-signals/src/actions/auth";
import { Logo } from "../Icons2/Logo";
import AppUrlListener from "../Components/AppUrlListener";
import { useIsWideScreen } from "../hooks/useIsWideScreen";
import { LargeSpinner } from "../Components/LargeSpinner";
import { SITE_NAME } from "../utils/constants";

//<script defer data-url="https://devhunt.org/tool/websemaphore" src="https://cdn.jsdelivr.net/gh/sidiDev/devhunt-banner/indexV0.js" />

export default function Web() {
  const [curr, progress] = current();

  const signedIn = curr?.id || curr?.username;
  const isWideScreen = useIsWideScreen();

  return progress?.value.inProgress && !signedIn ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LargeSpinner
        text={
          <>
            <div
              style={{
                width: isWideScreen ? 50 : 35,
                height: isWideScreen ? 50 : 35,
              }}
            >
              <Logo
                height={isWideScreen ? "50px" : "35px"}
                width={isWideScreen ? "50px" : "35px"}
              />
            </div>
            {SITE_NAME} booting...
          </>
        }
      />
      <AppUrlListener />
    </div>
  ) : (
    <></>
  );
}
