import React, { useEffect } from "react";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { getQueryParams } from "./Utils/getUrlParamsFromUrlSchemes";
import { useRouter } from "next/router";

const AppUrlListener: React.FC<any> = () => {
  const router = useRouter();
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const t = getQueryParams("newsafe_jwt", event.url) || "";
      localStorage.setItem(
        "newsafe-auth-token",
        t
      );
      Browser.close();
      router.reload();
    });
  }, []);

  return null;
};

export default AppUrlListener;
