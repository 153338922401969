export const Logo = ({
  onClick,
  width,
  height,
}: {
  onClick?: () => void;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "32"}
      height={height || "22"}
      viewBox="0 0 32 22"
      fill="none"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <path
        d="M21.9719 20.4556C25.9128 11.9841 23.6243 3.89869 21.9563 0.86377L20.9789 1.39662C22.5227 4.19215 24.7259 11.9069 20.9634 19.9845L21.9719 20.4556Z"
        fill="black"
      />
      <path
        d="M10.0901 0.903931C6.14921 9.37547 8.43769 17.4609 10.1055 20.4881L11.0831 19.9553C9.53931 17.1597 7.33612 9.44497 11.0985 1.36728L10.0901 0.903931Z"
        fill="black"
      />
      <path
        d="M27.4968 17.9912C17.1638 11.2418 7.66852 15.1802 4.02246 18.0221L4.79822 19.0105C8.15724 16.3927 17.0552 12.6704 26.8064 19.0415L27.4968 17.9912Z"
        fill="black"
      />
      <path
        d="M4.04102 3.56083C14.3896 11.0439 23.9159 6.67299 27.5697 3.52995L26.7474 2.58008C23.3884 5.47601 14.5215 9.57666 4.78574 2.54919L4.04102 3.56083Z"
        fill="black"
      />
      <path d="M16.6 20.448H15.3433V1.13416H16.6V20.448Z" fill="black" />
      <path
        d="M10.3331 19.8216C5.32169 19.8216 1.26448 15.7827 1.26448 10.7941C1.26448 5.80533 5.32169 1.76648 10.3331 1.76648H21.6669C26.6782 1.76648 30.7355 5.80533 30.7355 10.7941C30.7355 15.7827 26.6782 19.8216 21.6669 19.8216H10.3331ZM0 10.7863C0 16.47 4.62351 21.0726 10.3331 21.0726H21.6669C27.3687 21.0726 32 16.47 32 10.7863C32 5.10258 27.3765 0.5 21.6669 0.5H10.3331C4.62351 0.507722 0 5.11031 0 10.7863Z"
        fill="black"
      />
      <path
        d="M31.1705 11.4195H0.776367V10.1685H31.1705V11.4195Z"
        fill="black"
      />
    </svg>
  );
};
